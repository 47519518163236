import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { updateUser } from "../../store/slice";
import { useEffect } from "react";
import { authkey } from "../Login/authkey";
import usdt from "../../images/usdt.png";
import { apiUrl } from "../Login/baseurl";

const Withdraw = () => {
  const dispatch = useDispatch();
  var dashboard = new FormData();
  dashboard.append("dashboard", "");
  dashboard.append("auth", authkey);
  dashboard.append("logged", localStorage.getItem("auth"));


  useEffect(() => {
    fetch(apiUrl, {
      method: "POST",
      body: dashboard,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status == 200) {
          dispatch(updateUser(data.message.user));
        } else {
          navigate("/login");
        }
      });
  }, []);

  let withDrawData = {};
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [divData, setDivData] = useState("");
  const [amountInfo, setAmountInfo] = useState([]);
  const [doc, setDoc] = useState([]);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.data);

  var withdraw = new FormData();

  withdraw.append("withdraw", "");
  withdraw.append("auth", authkey);
  withdraw.append("logged", localStorage.getItem("auth"));
  useEffect(() => {
  fetch(apiUrl, {
    method: "POST",
    body: withdraw,
  })
    .then((res) => res.json())
    .then((wData) => {
      if (wData.status == 200) {
        withDrawData = wData;
     
        setDoc(withDrawData.message.document);
        if(withDrawData.message.doc > 0){
          
          document.getElementById('verifymainBlock').style.display='block';
        }
      } else {
        navigate("/login");
      }
    });
  }, []);
 
  let inputAmount, main_balanceW, min_balance, max_balance, fee;

  const checkUserAmount = document.getElementById("checkUserAmount");
  const info = document.getElementById("info");
  const verify = document.getElementById("verify");
  const verifySuccessContent = document.getElementById("verifySuccessContent");
  const successInfo = document.getElementById("successInfo");
  const mainBlock = document.getElementById("mainBlock");

  const handleClick = () => {
   
    fetch(apiUrl, {
      method: "POST",
      body: withdraw,
    })
      .then((res) => res.json())
      .then((wData) => {
        if (wData.status == 200) {
         
          withDrawData = wData;
          inputAmount = parseFloat(message);
    main_balanceW = parseFloat(user[0]?.main_balance);
    min_balance = parseFloat(withDrawData.message.min);
    max_balance = parseFloat(withDrawData.message.max);
    fee = parseFloat(withDrawData.message.fee);
    let newFee = (fee * inputAmount) / 100;
    setAmountInfo([inputAmount, min_balance, fee, newFee]);

    if(user[0]?.verified == "no"){
      mainBlock.style.display = "none";
      verify.style.display = "block";
    } else if(user[0]?.verified == "progress"){
      mainBlock.style.display = "none";
      verifySuccessContent.style.display = "block";
    } else {
  
      if (inputAmount) {
        if (inputAmount > main_balanceW) {
          info.style.display = "none";
          checkUserAmount.style.display = "block";
          setDivData("You do not have enough balance");
        } else if (inputAmount < min_balance) {
          info.style.display = "none";
          successInfo.style.display = "none";
          checkUserAmount.style.display = "block";
          setDivData(
            "Minimum Withdrawal amount is" + " " + min_balance + " " + "USDT"
          );
        } else if (inputAmount > max_balance) {
          successInfo.style.display = "none";
          info.style.display = "none";
          checkUserAmount.style.display = "block";
          setDivData(
            "maximum withdraw amount is " + " " + max_balance + " " + "USDT"
          );
        } else {
          successInfo.style.display = "none";
          checkUserAmount.style.display = "none";
          info.style.display = "block";
        }
      } else {
        setDivData("Type amount");
        successInfo.style.display = "none";
        info.style.display = "none";
        checkUserAmount.style.display = "block";
      }
    }
    
        } else {
          navigate("/login");
        }
      });
    
   
  };
  
  const submitPassportDrivingDocument = () => {
    const submitPassportDrivingDocumentBtn = document.getElementById("submitPassportDrivingDocumentBtn");
    const selectSwitch = document.getElementById("selectSwitch");
    const FrontPassDrivingError = document.getElementById("FrontPassDrivingError");
    const passDrivingFront = document.getElementById("passDrivingFront");

    submitPassportDrivingDocumentBtn.innerHTML = "Uploading...";
    var passDrivingFinalSubmit = new FormData();

    passDrivingFinalSubmit.append("verification", "");
    passDrivingFinalSubmit.append("type", selectSwitch.value);
    passDrivingFinalSubmit.append("front", passDrivingFront.files[0]);
    passDrivingFinalSubmit.append("auth", authkey);
    passDrivingFinalSubmit.append("logged", localStorage.getItem("auth"));

    fetch(apiUrl, {
      method: "POST",
      body: passDrivingFinalSubmit,
    })
      .then((res) => res.json())
      .then((wsData) => {
        if (wsData.status == 200) {
          verify.style.display = "none";
          verifySuccessContent.style.display = "block";
        } else if (wsData.status == 100) {
          if(wsData.message.uploadFront == 0){
            FrontPassDrivingError.innerHTML = '<p style="background:red;color:white;border-radius:10px;padding:5px;">'+wsData.message.front+'</p>';
          }else{
            FrontPassDrivingError.innerHTML = '<p style="background:green;color:white;border-radius:10px;padding:5px;">'+wsData.message.front+'</p>';
          }
         
        }
        submitPassportDrivingDocumentBtn.innerHTML = "Upload";
      });
  };
  const submitNidDocument = () => {
    const submitNidDocumentBtn = document.getElementById("submitNidDocumentBtn");
    const selectSwitch = document.getElementById("selectSwitch");
    const FrontNidError = document.getElementById("FrontNidError");
    const backNidError = document.getElementById("backNidError");
    const nidBack = document.getElementById("nidBack");
    const nidFront = document.getElementById("nidFront");

    submitNidDocumentBtn.innerHTML ="Uploading...";
    var nidFinalSubmit = new FormData();

    nidFinalSubmit.append("verification", "");
    nidFinalSubmit.append("type", selectSwitch.value);
    nidFinalSubmit.append("front", nidFront.files[0]);
    nidFinalSubmit.append("back", nidBack.files[0]);
    nidFinalSubmit.append("auth", authkey);
    nidFinalSubmit.append("logged", localStorage.getItem("auth"));

    fetch(apiUrl, {
      method: "POST",
      body: nidFinalSubmit,
    })
      .then((res) => res.json())
      .then((wsData) => {
        if (wsData.status == 200) {
          verify.style.display = "none";
          verifySuccessContent.style.display = "block";
        } else if (wsData.status == 100) {
          if(wsData.message.uploadFront == 0){
            FrontNidError.innerHTML = '<p style="background:red;color:white;border-radius:10px;padding:5px;">'+wsData.message.front+'</p>';
          }else{
            FrontNidError.innerHTML = '<p style="background:green;color:white;border-radius:10px;padding:5px;">'+wsData.message.front+'</p>';
          }
          if(wsData.message.uploadBack == 0){
            backNidError.innerHTML = '<p style="background:red;color:white;border-radius:10px;padding:5px;">'+wsData.message.back+'</p>';
          }else{
            backNidError.innerHTML = '<p style="background:green;color:white;border-radius:10px;padding:5px;">'+wsData.message.back+'</p>';
          }
       
        }

        submitNidDocumentBtn.innerHTML ="Upload";
      });

  };
  const handleSwitch = () => {
    const selectSwitch = document.getElementById("selectSwitch");
    const nidArea = document.getElementById("nidArea");
    const passport_driving = document.getElementById("passport_driving");

    if(selectSwitch.value == "null"){
      nidArea.style.display = "none";
      passport_driving.style.display = "none";
  } else if (selectSwitch.value == "nid") {
      passport_driving.style.display = "none";
      nidArea.style.display = "inline-grid";
    
    } else {
      nidArea.style.display = "none";
      passport_driving.style.display = "inline-grid";
    }
  };
  const confirmPassword = () => {
    const checkUserAmount = document.getElementById("checkUserAmount");

    if (user[0]?.secret_key == password) {
      var withdrawSubmit = new FormData();

      withdrawSubmit.append("withdraw", "");
      withdrawSubmit.append("save_request", "");
      withdrawSubmit.append("amount", amountInfo[0]);
      withdrawSubmit.append("auth", authkey);
      withdrawSubmit.append("logged", localStorage.getItem("auth"));

      fetch(apiUrl, {
        method: "POST",
        body: withdrawSubmit,
      })
        .then((res) => res.json())
        .then((wsData) => {
          if (wsData.status == 200) {
            checkUserAmount.style.display = "none";
            info.style.display = "none";
            successInfo.style.display = "block";
            setDivData("Withdraw Succesfully Requested");
          } else if (wsData.status == 100) {
            info.style.display = "none";
            successInfo.style.display = "none";
            checkUserAmount.style.display = "block";

            setDivData(wsData.message);
          } else {
          }
        });
    } else {
      info.style.display = "none";
      successInfo.style.display = "none";
      checkUserAmount.style.display = "block";
      setDivData("Withdraw password is incorrect ");
    }
  };
  const format = (x) => {
    return Number.parseFloat(x).toFixed(4);
  };

  const status = (x) => {
    if(x == "declined"){
      x = (<div className="badge badge-error gap-2">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-4 h-4 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
      Declined
    </div>);
    }
    if(x == "pending"){
      x = (<div className="badge badge-warning gap-2">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-4 h-4 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
      In review
    </div>);
    }
    if(x == "approved"){
      x = (<div className="badge badge-success gap-2">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
</svg>

      Approved
    </div>);
    }

    return x;
  };
 
  return (
    <div className="container max-w-[1080px] mx-auto p-5">
      <div className="bg-green text-white outline px-4 py-2 rounded-xl   flex items-center justify-between">
        <Link to="/profile" className="btn btn-base-200 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>{" "}
          Back
        </Link>
        <h1 className="text-xl font-bold text-center">Withdraw</h1>
      </div>

      <div className="card mx-auto bg-green shadow-xl mt-5">
        <div className="card-body">
          <div id="mainBlock">
          <h1 className="text-center text-xl font-bold">
            Balance{" "}
            <span className="text-xl font-bold">
              {format(user[0]?.main_balance)}{" "}
            </span>{" "}
            <img className="inline ml-1 h-[24px] w-[24px]" src={usdt} alt="" />
          </h1>
          <div className="flex flex-col sm:flex-row gap-5 mt-5 justify-center">
            <input
              type="number"
              placeholder="Withdraw Amount"
              className="input input-bordered"
              id="message"
              onChange={handleChange}
            />
            <button onClick={handleClick} className="btn btn-primary" >
              Withdraw
            </button>
          </div>
          </div>
          {/* <!-- display error message  --> */}
          <div>
            <section className="font-bold lg:text-xl md:text-base text-base">
              <div
                style={{ display: "none" }}
                id="checkUserAmount"
                className="text-center "
              >
                <br />
                <h1 className="my-5 flex justify-center  text-red-500">
                  {divData}{" "}
                </h1>
              </div>

              <div
                style={{ display: "none" }}
                id="successInfo"
                className="text-center "
              >
                <br />
                <h1 className="my-5 flex justify-center  text-green-500">
                  {divData}{" "}
                </h1>
              </div>
              <div
                style={{ display: "none" }}
                id="verifySuccessContent"
                className="text-center "
              >
                <div className="my-5 d-flex justify-center  bg-white rounded-lg shadow py-5">
                  <h1 className="my-5 d-flex justify-center text-sm  text-purple-800 md:text-xl">
                    Document has been submitted for review. Please wait until your document verified by our team.!{" "}
                  </h1>

                </div>
              </div>
              <div
                style={{ display: "none" }}
                id="verify"
                className="text-center "
              >
                <div className="my-5 d-flex justify-center  bg-white rounded-lg shadow py-5">
                  <h1 className="my-5 d-flex justify-center text-sm  text-purple-800 md:text-xl">
                    Verify Account!{" "}
                  </h1>

                  <h4 className="text-xs md:text-sm">
                    <select id="selectSwitch"  onChange={handleSwitch} className="select select-ghost w-full max-w-xs">
  <option value="null">Document Type</option>
  <option value="nid">National ID Card</option>
  <option value="passport">Passport</option>
  <option value="driving">Driving License</option>
</select>
                  {" "}
                  </h4>
                 
                  
                
                  <h4 className="text-xs md:text-sm">
                  <div style={{ display: "none" }} id="nidArea" className="gap-5 mt-5 justify-center">
                  <span className="">Upload Front Side: </span>
                  <input type="file" id="nidFront" className="file-input file-input-bordered file-input-xs w-full max-w-xs" />
                  <p id="FrontNidError"></p>
                  <span className="">Upload Back Side: </span>
                  <input type="file" id="nidBack" className="file-input file-input-bordered file-input-xs w-full max-w-xs" />
                  <p id="backNidError"></p>
                  <button
                      onClick={submitNidDocument}
                      className="btn btn-primary mx-5"
                    id="submitNidDocumentBtn"
                    >

                      Upload
                    </button>
                  </div>
                  <div style={{ display: "none" }} id="passport_driving" className="flex flex-col sm:flex-row gap-5 mt-5 justify-center">
                  <span className="">Upload Document: </span>
                  <input type="file" id="passDrivingFront" className="file-input file-input-bordered file-input-xs w-full max-w-xs" />
                  <p id="FrontPassDrivingError"></p>
                  <button
                      onClick={submitPassportDrivingDocument}
                      className="btn btn-primary mx-5"
                      id="submitPassportDrivingDocumentBtn"
                    >
                      Upload
                    </button>
                  </div>
                  </h4>
                  
                  
                   
                 
                </div>
              </div>

              <div
                style={{ display: "none" }}
                id="info"
                className="text-center "
              >
                <div className="my-5 d-flex justify-center  bg-white rounded-lg shadow py-5">
                  <h1 className="my-5 d-flex justify-center text-sm  text-purple-800 md:text-xl">
                    Withdrawal Confirmation!{" "}
                  </h1>

                  <h4 className="text-xs md:text-sm">
                    <span className="text-green-500">Withdraw Amount: </span>
                    <span className="text-black-500">
                      {amountInfo[0]} USDT{" "}
                      <img
                        className="inline ml-1 h-[20px] w-[20px]"
                        src={usdt}
                        alt=""
                      />
                    </span>{" "}
                  </h4>
                  <h4 className="text-xs md:text-sm">
                    <span className="text-green-500">Received Amount: </span>
                    <span className="text-black-500">
                      {amountInfo[0] - amountInfo[3]} USDT{" "}
                      <img
                        className="inline ml-1 h-[20px] w-[20px]"
                        src={usdt}
                        alt=""
                      />
                    </span>{" "}
                  </h4>
                  <h4 className="text-xs md:text-sm">
                    <span className="text-green-500">Transaction Fee: </span>
                    <span className="text-black-500">
                      {" "}
                      {amountInfo[3]} USDT{" "}
                      <img
                        className="inline ml-1 h-[20px] w-[20px]"
                        src={usdt}
                        alt=""
                      />
                    </span>
                  </h4>
                  <h4 className="text-xs md:text-sm">
                    <span className="text-green-500">New balance : </span>{" "}
                    <span className="text-black-500">
                      {" "}
                      {user[0]?.main_balance - amountInfo[0]} USDT{" "}
                      <img
                        className="inline ml-1 h-[20px] w-[20px]"
                        src={usdt}
                        alt=""
                      />
                    </span>
                  </h4>

                  <div className="flex flex-col sm:flex-row gap-5 mt-5 justify-center">
                    <input
                      type="text"
                      placeholder="Withdrawal Password"
                      className="input input-bordered mx-5"
                      id="password"
                      onChange={handlePassword}
                    />
                    <button
                      onClick={confirmPassword}
                      className="btn btn-primary mx-5"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>


      

      <div style={{display:"none"}} className="card mx-auto bg-green shadow-xl mt-5" id="verifymainBlock">
        <div className="card-body">
          <div >
           
          {doc?.map((m) => (
            
            <div
              key={m?.id}
              className="card mx-auto text-white bg-green shadow-xl w-full p-5"
            >
              <div className="flex justify-between items-center">
                <div className="flex gap-5 items-center">
                  <div className="">


                    <h1>{m?.type}</h1>
                    <div className="max-w-[600px]" id="dataSh"></div>
                    
                   
                    <small className="flex pb-1  pt-1">{m?.note}</small>
                   

                    
                   
                  </div>
                </div>
                <div className="flex gap-5 items-center">
                  <div className="">


                   {status(m?.status)}
                    <div className="max-w-[600px]" id="dataSh"></div>
                    
                   

                    
                    <small>{m?.date}</small>
                  </div>
                </div>
              </div>
              </div>
          ))}
         
          
          {/* <!-- display error message  --> */}
         
        </div>
      </div>
      </div>
    </div>
  );
};

export default Withdraw;
