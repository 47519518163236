import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { authkey } from "../Login/authkey";
import Transiction from "./Transiction";
import { apiUrl } from "../Login/baseurl";

const DepositeHistory = () => {
  const [dataLimit, setDataLimit] = useState();
  const [dipoHistory, setDipoHistory] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let count = 1;

  useEffect(() => {
    setLoading(true);
    var history = new FormData();
    history.append("history", "");
    history.append("deposit_history", "");
    history.append("limit", 500);
    history.append("auth", authkey);
    history.append("logged", localStorage.getItem("auth"));
    fetch(apiUrl, {
      method: "POST",
      body: history,
    })
      .then((res) => res.json())
      .then((depoHistoryData) => {
        if (depoHistoryData.status == 200) {
          setDipoHistory(depoHistoryData?.message);
          setLoading(false);
        } else {
          navigate("/login");
        }
      });
  }, []);

  let a1 = dipoHistory?.slice(0, 50);
  let a2 = dipoHistory?.slice(0, 100);
  let a3 = dipoHistory?.slice(0, 200);
  let a4 = dipoHistory?.slice(0, 300);
  let a5 = dipoHistory?.slice(0, 400);
  let a6 = dipoHistory?.slice(0, 500);

  let d = a1;

  if (dataLimit == 1) {
    d = a1;
  } else if (dataLimit == 2) {
    d = a2;
  } else if (dataLimit == 3) {
    d = a3;
  } else if (dataLimit == 4) {
    d = a4;
  } else if (dataLimit == 5) {
    d = a5;
  } else if (dataLimit == 6) {
    d = a6;
  } else if (dataLimit == 100) {
    d = a6;
  }

  const format = (x) => {
    return Number.parseFloat(x).toFixed(4);
  };
  return (
    <div >
   
    <Transiction></Transiction>
    <div className="container my-5 mx-auto max-w-[1080]">
        <div className="flex justify-between  mx-3">
        <select
          id="dataLimit"
          onChange={(e) => setDataLimit(e.target.value)}
          className="mx-auto select select-info select-bordered w-full "
        >
          <option vlaue={100}>All</option>
          <option value={1} defaultValue selected>
            50
          </option>
          <option value={2}>100</option>
          <option value={3}>200</option>
          <option value={4}>300</option>
          <option value={5}>400</option>
          <option value={6}>500</option>
        </select>
      </div>

    
      <section className="container my-7 mx-auto text-center">
          <div className="overflow-x-auto w-11/12 rounded shadow-lg mx-auto  ">
            <table className=" table table-compact w-11/12 text-center font-bold mb-16">
            <thead>
              <tr>
                <th></th>
                <th>Address</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Time</th>
              </tr>
            </thead>
            {loading ? (
            <div className="text-center">
              <svg
                role="status"
                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : null}
            {d?.map((p) => (
              <tbody key={p?.id}>
                <tr>
                  <th>{count++}</th>
                  <td>{p?.pay_address}</td>

                  <td>{format(p?.price_amount)}</td>

                  <td>
                    <div className="text-center ">
                      {p?.payment_status == "finished" ? (
                        <div className="flex font-bold ">
                          <span className="ml-2 rounded-lg badge badge-success gap-2">
                            Proceed
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      {p?.payment_status == "waiting" ? (
                        <div className="flex font-bold ">
                          <span className="ml-2 rounded-lg badge badge-warning gap-2">
                            Waiting Payment
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                      {p?.payment_status == "confirmed" ? (
                        <div className="flex font-bold ">
                          <span className="ml-2 rounded-lg badge badge-primary gap-2">
                            Being Proceed
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                      {p?.payment_status == "sending" ? (
                        <div className="flex font-bold ">
                          <span className="ml-2 rounded-lg badge badge-primary gap-2">
                            Being Proceed
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                      {p?.payment_status == "confirming" ? (
                        <div className="flex font-bold ">
                          <span className="ml-2 rounded-lg badge badge-primary gap-2">
                            Being Proceed
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      {p?.payment_status == "failed" ? (
                        <div className="flex font-bold ">
                          <span className="ml-2 rounded-lg badge badge-danger gap-2">
                            Failed
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                   
                   {p?.payment_status == "expired" ? (
                        <div className="flex font-bold ">
                          <span className="ml-2 rounded-lg badge badge-secondary gap-2">
                            Expired
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                   
                    </div>
                  </td>
                  <td>{p?.date}</td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </section>
      </div>
    </div>
  );
};

export default DepositeHistory;
