import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { Link, useNavigate } from "react-router-dom";
import { authkey } from "../Login/authkey";
import "./Deposit.css";
import usdt from "../../images/usdt.png";
import { apiUrl } from "../Login/baseurl";

const Deposit = () => {
  const [deposits, setDeposits] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const Completionist = () => <span>Your time ended!</span>;

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  var deposit = new FormData();
  deposit.append("auth", authkey);
  deposit.append("logged", localStorage.getItem("auth"));
  deposit.append("deposit", "");
  deposit.append("create", "");
  useEffect(() => {
    setLoading(true);
    fetch(apiUrl, {
      method: "POST",
      body: deposit,
    })
      .then((res) => res.json())
      .then((data) => {

        if (data.status == 200) {

          setDeposits(data);
          setLoading(false);
        } else {
          navigate("/login");
        }
      });
  }, []);


  return (
    <div className="container max-w-[1080px] mx-auto pb-10 ">
      <div className="bg-green text-white outline px-4 py-2 rounded-xl my-5 mx-3 flex items-center justify-between">
        <Link to="/profile" className="btn btn-base-200 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
          </svg> Back
        </Link>
        <h1 className="text-xl font-bold text-center">Deposit</h1>
      </div>

      {loading ? (
            <div className="text-center">
              <svg
                role="status"
                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : null}
      <div className="m-3">
        <div className="card w-100 bg-green text-white text-black shadow-xl">
          <div className="card-body">

            <h3 className="font-bold">
              <p>
                Minimum Amount: <span className="pl-1"> {deposits?.message?.min_amount}
                   <img className="inline ml-1 h-[20px] w-[20px]" src={usdt} alt="" />
                </span>
              </p>

            </h3>
            <h4 className="font-bold">
              <span>Network: </span>
              <span className="uppercase">{deposits?.message?.network}</span>
            </h4>
          
            <div className=" inline my-2">
            <h4 className=" px-1 py-1 font-bold text-base">Address</h4>
              <div className="form-control bg-white py-4 inline rounded ">

                <label className="input-group text-primary">
                  
                  <input id="copy" type="text" style={{ width: deposits?.message?.address.length * 9 + "px" }} value={deposits?.message?.address} className="border-0  inputt outline-0 rounded input input-bordered bg-white " readOnly />
                  <span className=" bg-slate-900 text-white font-bold" onClick={() => {
                    navigator.clipboard.writeText(document.getElementById('copy').value); document.getElementById('htmlCah').innerHTML = `Copied`;
                    setTimeout(function () {
                      document.getElementById('htmlCah').innerHTML = `Copy`;
                    }, 5000);
                  }}>
                    <div className=" bg-slate-900 text-white font-bold" id='htmlCah'>Copy</div>
                  </span>
                </label>
              </div>

            </div>



            <div>
              <h2 className="font-bold text-base md:text-xl  lg:text-xl">
                <i className="fa-solid fa-clock"></i> Time left to pay :{" "}
                <span className="text-green-100">
                
                  <Countdown date={Date.now() + 3600000} renderer={renderer} />
                </span>{" "}
              </h2>
              <h4 className="my-3 text-error p-3 rounded-lg" style={{backgroundColor:"#5cdeff"}}>
              <h4 style={{fontSize:"20px",backgroundColor:"#93e9ff"}}  className="rounded-lg text-white p-2">Note:</h4>
                <p>Do not send lower than the minimum USDT.
                <p>If you already sent the payment. wait up to 30 minutes to get credited into your account.</p>
               <br />
                </p><p>Pay only once to one address. Do not pay more than once to the same address.</p>
                <p>You can close this window</p>
                </h4>

                <h4 style={{backgroundColor:"#5cdeff"}} className="my-3 text-error p-3 rounded-lg"><h4 style={{fontSize:"20px",backgroundColor:"#93e9ff"}}  className=" rounded-lg text-white p-2">I have sent the payment but still waiting and i want to make another deposit. What should i do?</h4>
                <p className="">If you already sent the payment. wait up to 30 minutes to get credited into your account. Do not make any more payment. We process only one deposit at a time. Wait until complete the pending deposit.</p><p> once you have no pending deposit (<small> You sent the payment to the address and is complete or you did not send any payment</small>).By then you can deposit again</p>
                
                </h4>
            </div>
          </div>
        </div>
      </div>




    </div>
  );
};

export default Deposit;
